@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
    --bulma-scheme-main: hsl(
      var(--bulma-scheme-h)
      var(--bulma-scheme-s)
      var(--bulma-scheme-main-l)
    );
  }

[data-theme=my-theme],
  .theme-my-theme {
    --bulma-scheme-main-l: 7%;
    --bulma-scheme-main: hsl(
      var(--bulma-scheme-h)
      var(--bulma-scheme-s)
      var(--bulma-scheme-main-l)
    );
  }
  

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Rubik", sans-serif;
    background-color: #2e3440;
}

#tabs {
  background-color: #3b4252;
}

.ui-tabs-nav {
  background-color: #2e3440;
}

.box {
  background-color: #2e3440;
}

#table {
    display: grid;
    place-items: center;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
}